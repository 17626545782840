 @import "@glidejs/glide/src/assets/sass/glide.core";
 // Optional Theme Stylesheet
 @import "@glidejs/glide/src/assets/sass/glide.theme";

 .glide-styles {
     overflow: hidden;
     border-top-left-radius: 10px;
     border-top-right-radius: 10px;
 }

 .slide {
     padding-bottom: 10px;
     cursor: pointer;
     border-radius: 10px;

     &::marker {
         content: "" !important;
     }

     .img {
         width: 100%;
         height: 375px;
         object-fit: cover;
         border-radius: 10px;

         @media screen and (max-width: 800px) {
             object-fit: cover;
             border-radius: 0px;
         }
     }

 }

 .arrow {
     border: none;

     &.left {
         left: 0px;
     }

     &.right {
         right: 0px;
     }

     svg {
         circle {
             transition: all 0.3s ease-in-out;

             &:hover+g {
                 path {
                     fill: rgba(255, 255, 255, 0.926);

                 }
             }

             &:hover {
                 fill: rgba(255, 255, 255, 0.926);
             }
         }

         height: 30px;
         width: 30px;

     }
 }



 .thumbs {
     display: flex;

     .thumb {
         max-height: 100px;
         border-radius: 5px;
         padding: 5px;
     }
 }