.subheading2 {
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #ffffff80;


}

.xXSmall {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.15px;
    color: #ffffff80;
}

.xSmall {
    font-size: x-small;
}

.small {
    font-size: small;
}

.medium {
    font-size: medium;
}

.large {
    font-size: large;
}