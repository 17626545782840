:root {
  --mobile-button-border-radius: 37px;
  --primary-color: #98fff9;
  --background-color: #1a2329;
  --scrollbar-color: #111214;
  --soft-text-color: #ffffff70;
  box-sizing: border-box;

  scrollbar-width: thin;
  scrollbar-color: white;

  /* Works on Chrome, Edge, and Safari */
}

:root ::-webkit-scrollbar {
  width: 12px;
}

:root ::-webkit-scrollbar-track {
  background: var(--background-color);
}

:root ::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);

  border-radius: 20px;
  border: 3px solid var(--background-color);
}

html {
  /* height: 100%; */
  background-color: var(--background-color);
}

.html::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

hr {
  opacity: 0.4;
  border-width: 0.4px;
  margin: 8px 0;

}

#root

/* #root>div>div, */
/* #root>div  */
  {
  min-height: 100%;
  max-height: 100%;
  height: 100%;
  background-color: var(--background-color);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

body {
  overscroll-behavior-y: auto;
  margin: 0;
  height: 100%;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@media (max-width: 800px) {
  body {
    overflow: unset !important;
  }
}

body::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

a {
  color: inherit;
}

* {
  box-sizing: border-box !important;
  /* emotion lib brought in by chartJS-react overrides this 😡 */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@1,700&display=swap');

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  src: local(".SFNSText-Bold"), local(".HelveticaNeueDeskInterface-Bold"),
    local(".LucidaGrandeUI"), local("Ubuntu Bold"), local("Segoe UI Bold"),
    local("Roboto-Bold"), local("DroidSans-Bold"), local("Tahoma Bold");
}

@font-face {
  font-family: system;
  font-style: normal;
  font-weight: 300;
  src: local(".SFNSText-Light"), local(".HelveticaNeueDeskInterface-Light"),
    local(".LucidaGrandeUI"), local("Ubuntu Light"), local("Segoe UI Light"),
    local("Roboto-Light"), local("DroidSans"), local("Tahoma");
}

@font-face {
  font-family: pkmon;
  src: url("./assets/fonts/ptcg-webfont.woff2");
}

@font-face {
  font-family: pkmon2;
  src: url("./assets/fonts/ThraexSansRegular-v1000.woff2");
}

@font-face {
  font-family: fa;
  font-weight: 300;
  src: url("./assets/fonts/fa-light-300.woff2");
}

@font-face {
  font-family: fa;
  font-weight: 400;
  src: url("./assets/fonts/fa-regular-400.woff2");
}

@font-face {
  font-family: fa;
  font-weight: 900;
  src: url("./assets/fonts/fa-solid-900.woff2");
}

@font-face {
  font-family: fa-brands;
  font-weight: 400;
  src: url("./assets/fonts/fa-solid-900.woff2");
}

body {
  margin: unset;
  font-family: "Roboto", sans-serif;
}

.btn {
  /* box-shadow: 3px 3px 7px 0px lightgray; */
  border-radius: 3px;
  cursor: pointer;
  color: white;
}

@media (max-width: 600px) {
  .btn {
    border-radius: var(--mobile-button-border-radius);
  }
}

.btn.primary {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.btn.clear {
  background-color: transparent;
  color: dimgrey;
  min-width: 155px;
  height: 26px;
  /* border: 1px solid var(--primary-color); */
  border: none;
  font-size: 10px;
  line-height: 0.875rem;
}

.btn.clear:hover {
  background-color: whitesmoke;
}

.btn:hover {
  background: #9e6413;
}

/* 
.checkbox {
  line-height: 1.3;
  display: flex;
  align-items: center;
  grid-template-columns: 1em auto;
  gap: 1em;
  cursor: pointer;
} */

/* 
input[type="checkbox"] {
  appearance: none;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.35em;
  height: 1.35em;
  border: 0.05em solid lightgray;
  transform: translateY(-0.075em);
  cursor: pointer;
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: var(--background-color);
}

input[type="checkbox"]:checked {
  background: linear-gradient(180deg,
      #f48265,
      #fff5b8,
      #b8dca2,
      #b8e3ee,
      #7f99d0);
} */
/* 
input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:hover {
  background-color: lightgray;
}

input[type="checkbox"]:hover::before {
  transform: scale(1);
  background-color: dimgray;
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto" !important;
  font-style: normal !important;
  margin-bottom: 0 !important;
  font-weight: 400;
}

h1 {
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.18;
}

h2 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 24px;
}

h4 {
  font-weight: 700;
  font-size: 20px !important;
  line-height: 24px;
}

h5 {
  font-size: 0.875rem !important;
}

h6 {
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: 0.15px !important;
  color: #c4c4c4 !important;
}

.subtitle1,
sub {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
}

#search-results-container {
  position: relative;
  height: 100%;
  width: 100%;
}

sub {
  color: rgba(0, 0, 0, 0.6);
}

.grecaptcha-badge {
  bottom: 0px !important;
  right: -160px !important;
  transform: scale(0.5);
  z-index: 2;
  opacity: 0.2;
}

.rc-anchor- .grecaptcha-badge:hover {
  opacity: 1;
  right: -65px !important;
}

@media (max-width: 800px) {
  .grecaptcha-badge {
    bottom: 0px !important;
    right: -160px !important;
    transform: scale(0.5);
    z-index: 0;
    opacity: 0.2;
  }

  .grecaptcha-badge:hover {
    opacity: 1;
    right: -25px !important;
  }
}

@keyframes transition {
  0% {
    background-position: 10% 0%;
  }

  50% {
    background-position: 91% 100%;
  }

  100% {
    background-position: 0% 10%;
  }
}

.undefined {
  color: red;
}

.undefined::after {
  content: " (undefined class name)";
}

listing-search-bar {
  display: flex;
  column-gap: 10px;
}

[id^="zoid-paypal-buttons"] {
  z-index: 1;
}

.subtle {
  color: #ffffff70;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.flex-row-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-column-gap-5 {
  column-gap: 5px;
}

.flex-column-gap-10 {
  column-gap: 10px;
}

.flex-column-gap-15 {
  column-gap: 10px;
}

.flex-column-gap-20 {
  column-gap: 10px;
}


.f-s-xsmall {
  font-size: x-small;
}

.f-s-small {
  font-size: small;
}

.f-s-medium {
  font-size: medium;
}

.f-w-500 {
  font-weight: 500;
}

.f-w-700 {
  font-weight: 700;
}

.l-s-03 {
  letter-spacing: 0.3px;
}

.l-h-18 {
  line-height: 18px;
}

.Mui-disabled {
  color: var(--soft-text-color) !important;
  -webkit-text-fill-color: var(--soft-text-color) !important;
}




/* clear chrome autofill style for input */
input:-internal-autofill-selected {
  appearance: unset !important;
  background-image: none !important;
  background-color: blue !important;
  color: fieldtext !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}