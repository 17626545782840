@use 'variables.scss';
@use 'mixins.scss';

select {
    width: 100%;
    // height: 50px;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    border-radius: 0;
    background-color: variables.$documentBackgroundColor;
    border: none;
    // border-bottom: 1px solid #FFFFFF40;
    color: white;
    appearance: none;
    padding: 10px;
    padding-right: 38px;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: color 0.3s ease, background-color 0.3s ease;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.25' d='M0 0L5 5L10 1.74846e-06L0 0Z' fill='white'/%3E%3C/svg%3E%0A");

    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    background-size: 0.65rem auto;


}

select {
    @include mixins.scrollbar();
}

option {
    font-size: 1rem;
    font-weight: 400;
    // height: 20px;
    background-color: variables.$documentBackgroundColor;
    color: white;

    &:hover {
        background-color: #FFFFFF40;
        cursor: pointer;
    }
}

.select-icon {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 20px;
    height: 10px;
    pointer-events: none;
    border: 2px solid variables.$documentBackgroundColor;
    padding-left: 5px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

select:hover,
select:focus {
    color: #DCDCDC;
    border-bottom-color: #DCDCDC;
    cursor: pointer;
}

select:hover~.select-icon,
select:focus~.select-icon {
    background-color: white;
    border-color: #DCDCDC;
}

select:hover~.select-icon svg.icon,
select:focus~.select-icon svg.icon {
    fill: variables.$documentBackgroundColor;
}