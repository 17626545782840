.chipsCollection {
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
    z-index: 0;


    .chip {
        z-index: 0;


        &:hover {
            outline: 1px solid #FFFFFF40;
        }

        button {
            width: 150px !important;
        }

        .countBadge {
            position: relative;
            display: flex;
            z-index: 1;
            justify-content: center;
            align-items: center;
            opacity: 0.5;
            bottom: 0px;
            right: 0px;
            background-color: #FFFFFF20;
            color: #FFF;
            padding: 2px 4px;
            border-radius: 50px;
            font-size: 0.6rem;
            width: auto;
            height: auto;
            box-shadow: 0px 0px 22px 1px #FFFFFF60;
        }
    }


    .highlight {
        z-index: 1;
        padding: 1px;
        color: #FFF !important;
    }
}