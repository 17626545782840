@use "./dialog.scss";
@use "./mixins.scss";
@use "./variables.scss";

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.app {
  background-color: var(--background-color);
  height: 100%;
}

.main {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 60px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .searchBar {
    // padding: 65px 10px 20px 10px;
    transition: height 0.5s ease;
  }

  .headerBar {
    @include mixins.hideWhenLessThan800() {
      display: none !important;
    }
  }

  .searchResults {
    display: flex;
    justify-content: center;
    margin: 0 20px;
    column-gap: 20px;
    // margin-right: 130px;

    .imagePanel {
      @include mixins.hideWhenLessThan(1700px);
    }
  }
}

.footer {
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;

  >div {
    max-width: 662px;
    display: flex;
    flex-direction: column;
    line-height: 24px;

    a {
      color: white;
      text-decoration: none;
      font-weight: 200;

      &:hover {
        text-decoration: underline;
      }
    }

  }
}



@include mixins.maxWidth1300() {
  // .app {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: flex-start;
  //   height: calc(100% - 100px);
  //   @include mixins.scrollY();
  // }

  .selectedFiltersPanel {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: flex-start;
  }

  .main {
    .searchResults {
      display: unset;
      justify-content: unset;
      // margin: 100px 0 100px 0;
      width: 100%;
      margin-right: 0px;
      margin: 0 0px;

      &.fullHeight {
        height: 100vh;
      }

      .filterPanel {
        display: none;
      }
    }
  }
}