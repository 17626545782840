@use 'variables';
@use 'mixins';

.searchBarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  height: 60px;
  padding: 100px 8px;
  width: 100%;
  // padding-left: 60px;
  max-width: 1170px;

  .mobileSearchBar {
    @include mixins.hideWhenNotMobile();
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .staticContent {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      padding-bottom: 8px;
    }

    .searchListingsTitle {
      text-transform: capitalize;
      text-align: center;
      flex: 1;
      user-select: none;

    }

    .back {
      color: #EE8F00;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;
      width: 100px;

    }

    .title {
      display: flex;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 1.1px;
      align-items: center;
      justify-content: space-between;
      text-shadow: 0px 0px 1px;
      // padding: 0 0 16px 16px;

      width: 100%;
      height: 100%;
      text-align: center;
    }

    .searchButton {
      color: white;
    }
  }

  &>listing-search-bar {
    max-width: variables.$maxCenterWidth;
    width: 86%;
  }

  &.hideMobileSearchBar {
    padding: 0;
    display: none;
  }

  @include mixins.maxWidth800() {
    background-color: variables.$headerBarColor;
    display: flex;
    position: fixed;
    align-items: flex-end;
    width: 100%;
    max-width: 100%;
    height: auto;
    transition: all 0.2s ease;
    padding: 8px;


    &.hideSearchBar {
      transform: translateY(-100%);

      padding: 0;

      span,
      span img,
      input {
        display: none !important;
      }

      &.hideFullSearchBar {
        padding: 0;
      }
    }


  }


}

.plus {
  cursor: pointer;
}

.headerBar {
  background: variables.$headerBarColor !important;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 44px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 10;
  height: 60px;
  @include mixins.hideWhenLessThan800;

  .profilePic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
  }


  user-name {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    // width: 100%;
    cursor: pointer;

    // .name {
    //   @include mixins.scrollbar(900px);
    // }
  }

  .userName {
    cursor: pointer;

    .name {
      @include mixins.scrollbar(900px);
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 45px;


    a :link {
      color: white;
    }

  }
}


.cartCount {
  position: absolute;
  top: 5px;
  right: 10px;
  background: #FF0000;
  color: white;
  border-radius: 50%;
  padding: 0 5px;
  font-size: 12px;
  font-weight: bold;
  transform: translate(50%, -50%);
  z-index: 10;

}