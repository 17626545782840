 @use './variables';
 @use './mixins';

 .userName {
     display: flex;
     column-gap: 5px;
     align-items: center;
     width: 100%;
     border-radius: 5px;

     .rating {
         display: flex;
         color: #EE8F00;
         width: 50%;
         justify-content: flex-end;
         align-items: center;
         flex: 1;

         span {
             display: flex;
             font-size: 12px;
         }

         svg {
             font-size: 16px;
         }
     }
 }

 .user {
     font-size: 12px;
     width: 100px;
     text-overflow: ellipsis;
     overflow: hidden;
     white-space: nowrap;

 }

 .userrating {
     display: flex;
     margin-left: 57px;
     column-gap: 3px;
     justify-content: flex-end;
     align-items: center;
     margin-left: auto
 }

 .profilePic {
     border-radius: 50%;
     cursor: pointer;
     height: 21px;

     svg {
         width: 16px;
         height: 16px;
     }

     transition: all 0.3s ease;
 }


 .ratingstar {
     height: 16px;
     width: 16px;

     &.rated {
         fill: #EE8F00
     }

     &.unrated {
         stroke: #EE8F00;
         fill: transparent;
     }

 }