@use "variables.scss";
@use "mixins.scss";

.root {
  width: 100%;
  min-height: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  // align-items: center;
  background: variables.$documentBackgroundColor;
  color: white;

  .form {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 60px 0;
    // width: 100%;
    // max-width: 980px;


    .stepContent {
      width: 100%;
      display: flex;
      position: relative;
      flex-direction: row;
      height: 100%;
      column-gap: 20px;
      max-width: variables.$maxCenterWidth;
      justify-content: space-between;
      align-items: flex-start;

    }
  }

  .stepperButtonContainer,
  .stepperContainer {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #252D32;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
    height: 60px;
    z-index: 1;

    .stepper {
      display: flex;
      justify-content: space-around;
      width: 100%;
      column-gap: 16px;
      align-items: center;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-basis: max-content;
        min-width: 50px;
        text-decoration: none;
        border-radius: 50px;
        width: 100%;
        color: #929698;
        height: 30px;
        font-size: 0.6875rem;
        font-weight: 600;
        letter-spacing: 1.25px;
        text-transform: uppercase;

        &.selectedStep {
          height: 40px;
          background-color: #161D22;
          color: #98FFF9;
          box-shadow: 0px 1px 2px 1px #00000090;
          padding: 15px;
        }
      }
    }

  }

  .transaction {
    padding: 10px;
    display: flex;
    flex-direction: column;
    row-gap: .875rem;

    .startAdornment {
      color: variables.$lightGrayBackground2;
      width: 20px;
    }

    .makeAnOffer {
      display: flex;
      flex-direction: column;
      row-gap: 8px;

    }


    .input {
      margin-top: 20px;
      width: 250px;
    }
  }

  .selectedCardsPreviewContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: .875rem;
    justify-content: space-evenly;
    max-height: 600px;
  }
}


@media (max-width: 1000px) {

  .root {
    max-width: 100vw;


  }

  .stepperContainer,
  .stepperButtonContainer {
    .stepper {
      column-gap: 0px !important;

      .stepLine {
        width: 8px;
        margin: 0 2px;
        border-top: 1px solid white;
      }

      a {
        position: relative;
        border: 2px solid white;
        padding: 0px !important;
        width: 40px !important;
        height: 40px !important;
        min-width: 40px !important;
        color: white !important;
        font-size: medium !important;



        &.selectedStep {
          padding: 0 !important;
          height: 40px !important;
          box-shadow: 0px 1px 15px 0px rgba(255, 255, 255, 0.56) !important;

        }
      }
    }
  }
}

@media (max-width: 800px) {
  .root {
    padding: 8px;
  }

  .stepperButtonContainer {
    padding: 0 8px;
    display: flex;
    height: 100%;
    width: 100%;
    padding-bottom: 60px;

    button {
      min-width: 80vw !important;
    }

    .stepper {
      a {
        min-width: 40px;
      }
    }
  }

  .mobileRoot {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}